<template>
  <layout-default>
    <v-card prepend-icon="mdi-ballot">
      <template v-slot:title>
        <v-row>
          <v-col cols="12" md="6">Category</v-col>
          <v-col cols="auto" md="6" class="text-right">
            <v-row class="d-flex justify-end">
              <v-col cols="auto">
                <v-btn  density="comfortable" color="primary" @click="createDialog()">create</v-btn>
              </v-col>
              <v-col cols="auto">
                <input type="file" ref="file" style="display: none"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="importFile"/>
                <v-btn  density="comfortable" color="teal-accent-4" @click="$refs.file.click()" :loading="importLoading">Import</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="3" v-for="item in categories" :key="item.name">
            <v-card class="mx-auto mb-5" height="450">
              <v-img
                  class="align-end text-white"
                  height="200"
                  :src="item.image ? $backendUrl +'storage/category/' + item.image : 'no_image.png'"
                  cover
              >
              </v-img>
              <v-card-text>Id: {{item.id}}</v-card-text>
              <v-card-title>{{ item.name }}</v-card-title>
              <v-card-text>
                <div class="text-truncate">{{ item.description }}</div>
              </v-card-text>
              <v-card-subtitle class="pt-4">{{ item.created_at }}</v-card-subtitle>
              <v-card-actions>
                <v-chip variant="outlined" class="ma-2" color="green" text-color="white" v-if="item.is_active">Active
                </v-chip>
                <v-chip variant="outlined" class="ma-2" color="red" text-color="white" v-else>InActive</v-chip>
                <v-spacer></v-spacer>
                <v-btn size="small" color="teal-accent-4" variant="text" icon="mdi-eye"
                       @click="$router.push('/category/'+ item.id +'/tradings')"></v-btn>
                <v-btn size="small" color="orange" variant="text" icon="mdi-pencil" @click="editPopup(item)"></v-btn>
                <v-btn size="small" color="red" variant="text" icon="mdi-delete" @click="deleteItem(item.id)"></v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
<!--        <div class="text-center" v-if="categories.data">-->
<!--          <v-container>-->
<!--            <v-row justify="center">-->
<!--              <v-col cols="8">-->
<!--                <v-container class="max-width">-->
<!--                  <v-pagination-->
<!--                      v-model="page"-->
<!--                      class="my-4"-->
<!--                      :total-visible="5"-->
<!--                      :length="categories.last_page"-->
<!--                  ></v-pagination>-->
<!--                </v-container>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-container>-->
<!--        </div>-->
      </v-card-text>
    </v-card>

    <v-row justify="space-around">
      <v-dialog v-model="dialog" persistent width="600">
        <v-card>
          <v-toolbar color="primary" :title="edit ? 'Update category' : 'Create New Category'"></v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Category name*" required v-model="categoryForm.name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea autocomplete="description" label="Description" rows="2"
                              v-model="categoryForm.description"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea autocomplete="note1" label="Note1" rows="2" v-model="categoryForm.note1"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea autocomplete="note2" label="Note2" rows="2" v-model="categoryForm.note2"></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-switch
                      v-model="categoryForm.is_active"
                      hide-details
                      :true-value=1
                      :false-value=0
                      :label="`Is Active: ${categoryForm.is_active}`"
                      color="primary"
                      inset
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-file-input label="Image" accept="image/*" show-size prepend-icon="mdi-camera"
                                @change="selectFile"></v-file-input>
                </v-col>
                <v-col class="12" v-if="previewImage">
                  <img :src="previewImage" width="150" height="100">
                </v-col>
                <v-col class="12" v-else-if="categoryForm.image">
                  <img :src="$backendUrl+'storage/category/' + categoryForm.image" width="150" height="100">
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange" @click="dialog = false" class="me-4 text-white">Close</v-btn>
            <v-btn color="primary" @click="create()" class="me-4 text-white" v-if="!edit">Save</v-btn>
            <v-btn color="primary" @click="update()" class="me-4 text-white" v-else>Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">{{ snackbarMsg }}
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="snackbar = false"><v-icon icon="mdi-close-circle" /></v-btn>
      </template>
    </v-snackbar>
  </layout-default>
</template>

<script>

import LayoutDefault from "@/components/LayoutDefault.vue";
import Form from 'vform'

export default {
  name: 'CategoryList',
  components: {LayoutDefault},
  props: {
    msg: String,
  },
  data: () => ({
    dialog: false,
    categories: [],
    categoryForm: new Form({
      id: null,
      parent_id: null,
      name: '',
      description: '',
      note1: '',
      note2: '',
      image: null,
      is_active: 0
    }),
    previewImage: '',
    edit: false,
    snackbar: false,
    snackbarMsg: '',
    timeout: 3000,
    snackbarColor: "black",
    importLoading:false,
    page:1
  }),

  created() {
    this.fetch();
  },
  computed: {
    primaryCategory() {
      var result = this.categories.data.filter(item => item.parent_id == null)
      return result;
    }
  },
  methods: {
    fetch() {
      this.edit = false
      this.axios.get("/category").then((response) => {
        if (response.status == 200 && response.data.status) {
          this.categories = response.data.data
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    createDialog() {
      this.categoryForm.reset();
      this.edit = false;
      this.dialog = true;
      this.previewImage = null;
    },
    selectFile(e) {
      this.categoryForm.image = e.target.files[0]
      if (this.categoryForm.image) {
        this.previewImage = URL.createObjectURL(this.categoryForm.image);
      }
    },
    create() {
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.axios.post("/category", this.categoryForm.data()).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.categoryForm.reset()
          this.dialog = false
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.fetch();
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    editPopup(item) {
      this.previewImage = null;
      this.edit = true
      this.categoryForm.reset()
      this.dialog = true
      this.categoryForm.fill(item)
    },
    update() {
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.categoryForm._method = 'PUT';
      this.axios.post("/category/" + this.categoryForm.id, this.categoryForm.data()).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.categoryForm.reset()
          this.dialog = false
          this.edit = false
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.fetch();
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });

    },

    async deleteItem(id) {
      let confirm = window.confirm("You want to delete?")
      if (confirm) {
        await this.axios.delete("/category/" + id).then((response) => {
          if (response.status == 200 && response.data.status) {
            this.snackbar = true
            this.snackbar = response.data.message
            this.snackbarColor = "green"
            this.fetch();
          } else {
            this.snackbar = true
            this.snackbarMsg = response.data.message
            this.snackbarColor = "red"
          }
        }).catch(function (error) {
          console.log("error: ", error);
        });
      }
    },

    importFile(e) {
      this.importLoading = true
      let file = e.target.files[0]
      this.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
      this.axios.post("/category/import", {file:file}).then((response) => {
        // console.log(response.data)
        if (response.status == 200 && response.data.status) {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "green"
          this.fetch();
        } else {
          this.snackbar = true
          this.snackbarMsg = response.data.message
          this.snackbarColor = "red"
        }
      }).catch(function (error) {
        console.log("error: ", error);
      });
      this.importLoading = false
    }

  },
  watch: {
    page(pageNo){
      this.fetch(pageNo);
    }
  }
}
</script>

<style scoped>

</style>
